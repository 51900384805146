@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*
 Mediaquery management breakpoints
 0 - 600px: mobile
 600px - 900px: Tablet portrait
 900px - 1200px: Tablet landscape
 1200 - 1800: Standard style (Desktop)
 1800px++: Big Desktop

 $breakpoint argument choices:
 - phone
 - tab-port
 - tab-land
 - big-desktop

 Calculation of breakpoints
 Order: Base + typography > general layout + grid > page layout > components

 1em = 16px
*/

// Defining breakpoints
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    }
  }

  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    }
  }

  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    }
  }

  @if $breakpoint == big-desktop {
    @media only screen and (max-width: 112.5em) {
      @content;
    }
  }
}
