html {
  background-color: $background-color;
  font-size: 75.5%; // 65.5
  @include respond(tab-land) {
    font-size: 56.25%;
  }
  @include respond(tab-port) {
    font-size: 50%;
  }
  @include respond(phone) {
    font-size: 45%;
  }
  @include respond(big-desktop) {
    font-size: 75%;
  }
}

body {
  font-family: $font-family-name, sans-serif;
  box-sizing: border-box;
  padding: 2rem;
  letter-spacing: 0.045rem;
  @include respond(tab-port) {
    padding: 0;
  }
}

body .p-component {
  font-family: "TTNorms", Arial, Helvetica, sans-serif;
}

.custom-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.5rem;
  height: 4.5rem;
  animation: spin-dash 1s linear infinite;
  // animation:
  //   spin-dash 1.2s ease-in-out infinite,
  //   spinner-color 6s ease-in-out infinite;
}

@keyframes spin-dash {
  // 0% {
  //   stroke-dasharray: 10, 100;
  //   stroke-dashoffset: 0;
  // }
  // 50% {
  //   stroke-dasharray: 20, 100;
  //   stroke-dashoffset: -45px;
  // }
  // 100% {
  //   stroke-dasharray: 20, 100;
  //   stroke-dashoffset: 55px;
  // }
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(90deg);
  }
  70% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.background-wrapper {
  padding: 1rem 1rem;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 32px;
}

.central-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.modal-container {
  width: 424px;
  @include respond(phone) {
    width: 100%;
    border-radius: 0px;
    border: 0px;
    padding: 0.5rem 0.1rem;
  }
  margin: 1rem 0rem;
  border-radius: 1.5rem;
  border: 1px solid var(--basic-700, #d8e0f0);
  background: #fff;
  padding: 1.5rem 1.5rem;
  // width: 26.5rem; // No queda como diseño figma
  // flex-shrink: 0;
}

.qr-container {
  display: flex;
  padding: 1.9375rem 6.9375rem 1.625rem 6.9375rem;
  justify-content: center;
  align-items: center;
  height: 18.5rem;
}

.sale-code-container {
  display: flex;
  // width: 20.4375rem;
  // padding: 0rem 6.3125rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  .rounded-container {
    display: flex;
    flex-wrap: row wrap;
    padding: 0.375rem 0.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 3.5rem;
    background: var(--primary-transparent-3, rgba(0, 45, 249, 0.03));
  }
  .code-text {
    color: #657085;
    text-align: center;
    font-size: 14px;
    // font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.25rem;
    .code-number {
      font-weight: bold;
      color: #0c122d;
      line-height: 1.25rem;
    }
  }
}

.container-title {
  display: flex;
  margin: 2rem 1rem;
  // width: 23.5rem;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  .text-big-01 {
    color: var(--grey-500-default, #0c122d);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    line-height: 32px;
    @media only screen and (max-width: 768px) {
      /* For mobile phones: */
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
  .title {
    color: #0c122d;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
  }
}

.sale-detail-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1.5rem;
  color: #657085;
  font-size: 16px;
  line-height: 24px;
  margin: 2rem 1rem;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    font-size: 1rem;
    line-height: 2rem;
  }
  .detail-line {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .detail-text {
      // font-weight: bold;
      font-weight: 500;
      color: #0c122d;
      line-height: 24px;
      // line-height: 1.5rem;
      font-feature-settings:
        "clig" off,
        "liga" off;
    }
  }
}

.button-container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  padding: 2rem 1rem 2rem 1rem;

  .hint-text-mobile {
    padding: 1rem;
    font-feature-settings:
      "clig" off,
      "liga" off;
    margin-bottom: 1.5rem;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    color: var(--grey-500-default, #0c122d);
    text-align: center;

    .continue-text {
      font-weight: bold;
      color: #0c122d;
      line-height: 1.25rem;
    }
  }
}

.button-container button {
  width: 100%; /* Set the button width to 100% to expand it within the container */
}

.notification-container {
  background-color: #ebf1ff;
  border-radius: 10px;
  flex-shrink: 0;
  display: flex;
  padding: 2rem 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  .notification-title {
    color: #0c122d;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
  }
  .notification-instruction {
    color: #657085;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    .special-text {
      color: #0c122d;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
  .notification-path {
    color: #0c122d;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}

.copy-toclipboard-container {
  display: flex;
  justify-content: start;
  width: 100%;
  align-items: center;
  gap: 1rem;
  .code-label {
    color: #657085;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  .code-value {
    color: #0c122d;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}

.waiting-auth-container {
  display: flex;
  position: relative;
  // width: 23.4375rem;
  height: 13.1875rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: #ebf1ff;
  border-radius: 1rem;
  margin: 0.5rem;

  .waiting-image {
    width: 15rem;
    height: 13.3rem;
    flex-shrink: 0;
    padding-top: 3rem;
    box-sizing: border-box;
  }

  .loader-container {
    position: absolute;
    width: 4.5rem;
    height: 4.5rem;
    flex-shrink: 0;
    box-sizing: border-box;
    top: 0;
    right: 0;
    border-radius: 2.25rem;
    background: #fff;
    margin: 0.5rem;
  }
}

.generate-code-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: #ebf1ff;
  border-radius: 1rem;
  margin: 0.5rem;
  height: 18.5rem;

  .generate-qr-img {
    width: 85%;
    // width: 28rem;
    // width: 20rem;
    height: 20rem;
    flex-shrink: 0;
    padding-top: 3rem;
    box-sizing: border-box;
  }
  .welcome-logo-mobile {
    flex-shrink: 0;
    padding-top: 3rem;
    box-sizing: border-box;
    margin-left: 18px;
  }
}

.subtitle-mobile-case {
  color: #657085;
  line-height: 1.5rem;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: normal;
}

.subtitle-desktop {
  color: #657085;
  line-height: 32px;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.p-button {
  color: $blue-semantic-info-600;
  // background: $green-accent-500;
  border: 1px solid transparent;
  background-color: $green-accent-500;
  width: 100%; /* Full width for buttons on small screens */
}

.p-button.p-button-text {
  background-color: transparent;
  color: $blue-semantic-info-600;
  width: 100%; /* Full width for buttons on small screens */
}

.custom-button {
  width: 50%; /* Full width for buttons on small screens */
  background-color: rgba(0, 45, 249, 0.05);
  border-radius: 8px;
  justify-content: center;
}

.custom-button-submit {
  width: 50%; /* Full width for buttons on small screens */
  background-color: $green-accent-500;
  border-radius: 8px;
  justify-content: center;
}

.p-button-label {
  font-weight: 500;
}

.p-button.p-button-rounded {
  border-radius: 1rem;
}

.p-button.p-button-outlined {
  color: $blue-semantic-info-600;
}

.p-button.p-button-anular {
  padding-top: 3rem;
  font-size: 14px;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    font-size: 12px;
  }
}

.p-button.p-button-large {
  border-radius: 1rem;
  height: 3.5rem;
  font-size: 14px;
}

.base-error-container {
  display: flex;
  flex-flow: column nowrap;
  padding: 48px;
  .error-icon-container {
    display: flex;
    justify-content: center;
    .base-logo {
      flex-shrink: 0;
      padding-top: 3rem;
      box-sizing: border-box;
      width: 100%;
      // @include respond(phone) {
      //   width: 70vw;
      // }
    }
    .error-svg {
      flex-shrink: 0;
      padding-top: 3rem;
      box-sizing: border-box;
    }
  }
  .error-title {
    padding: 1.5rem;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;
    color: var(--grey-500-default, #0c122d);
    text-align: center;
  }
  .error-subtitle {
    font-feature-settings:
      "clig" off,
      "liga" off;
    color: var(--grey-400, #657085);
    text-align: center;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.5rem;
    margin-bottom: 10rem;
  }
}

.alert-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.5rem;
  height: 4.5rem;
}

.count-down-timer {
  box-shadow: 2px 6px 9px 2px rgb(0 0 0 / 20%);
  text-align: center;
  // background-color: #313b3f;
  max-width: 400px;
  margin: 20px auto;
  font-weight: 500;
  border-radius: 6px;
  padding: 10px;
  .times {
    display: grid;
    grid-template-rows: repeat(4, calc(25% - 8px));
    grid-column-gap: 10px;

    font-size: 1.5rem;
    // position: relative;
  }
}

.info-container {
  height: 6rem;
  background: #f5f7ff;
  padding-inline: 30px;
  border-radius: 10px;
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  justify-content: space-evenly;
  align-items: center;
}

.info-text {
  color: #0c122d;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

// TODO: checar siempre estas clases cuando se haga upgrade de PRIMENG.
.p-dialog {
  border-radius: 20px; /* Adjust the value as needed */
  // border-radius: 20px !important; /* Adjust the value as needed */
  .p-dialog-content {
    border-radius: 20px;
  }
}
